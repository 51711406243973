import * as React from "react";
import { useProvidedNotifications } from "contexts";
import { makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Debug from "debug";

const debug = Debug("app:comp:notifications");

const useStyles = makeStyles(() => ({
  parent: {
    position: "absolute",
    right: "50px",
    top: "50px",
    maxWidth: "300px",
    zIndex: 10,
  },
  notification: {
    marginBottom: "10px",
  },
}));

const Notifications = (props) => {
  const [notifications = {}] = useProvidedNotifications();
  const classes = useStyles();
  debug("notifications are %j", Object.values(notifications));
  return (
    <div className={classes.parent}>
      {Object.values(notifications).map(({ level = "info", body = "", id }) => (
        <Alert key={id} severity={level} className={classes.notification}>
          {body}
        </Alert>
      ))}
    </div>
  );
};

export default Notifications;
