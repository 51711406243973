/** eslint-env browser */
import { apiBaseURL } from "../config";
import Debug from "debug";

const debug = Debug("app:lib:sendRequest");
class RequestError extends Error {
  constructor(err, status, ...params) {
    super(err.message, status, ...params);
    this.message = err.message;
    this.status = status;
  }

  toString() {
    return `Request failed with status code ${this.status}`;
  }
}

const parseToQs = (payload = {}) =>
  Object.entries(payload)
    .map(([key, value]) => {
      if (typeof value === "object") {
        return `${key}=${JSON.stringify(value)}`;
      }
      return `${key}=${value}`;
    })
    .join("&");

const sendRequest = async (requestOpts) => {
  debug("sendRequest", requestOpts);
  const {
    url,
    method,
    form = false,
    file = false,
    json = !form && !file,
    body,
    qs,
    headers,
    bearer,
    endpoint,
  } = requestOpts;

  const parsedURL =
    (endpoint ? `${apiBaseURL}/${endpoint}` : url) +
    (qs ? `?${parseToQs(qs)}` : "");

  let parsedBody;
  if (json && body) {
    parsedBody = JSON.stringify(body);
  } else if (body && form) {
    parsedBody = new window.FormData();
    Object.entries(body).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((val) => {
          parsedBody.append(key, val);
        });
        return;
      }
      parsedBody.append(key, value);
    });
  }
  debug("start fetch");
  const response = await window.fetch(parsedURL, {
    method,
    headers: {
      ...(headers || {}),
      ...(json && body
        ? {
            Accept: "application/json",
            "Content-Type": "application/json",
          }
        : {}),
      ...(bearer ? { Authorization: `Bearer ${bearer}` } : {}),
    },
    body: parsedBody,
  });

  debug("end fetch");

  let responseBody;
  // console.log(response.json());
  // console.log(response.text());
  if (file && response.status < 300) {
    const blob = await response.blob();
    const blobURL = URL.createObjectURL(blob);
    // Create a new anchor element
    const a = document.createElement("a");
    // Set the href and download attributes for the anchor element
    // You can optionally set other attributes like `title`, etc
    // Especially, if the anchor element will be attached to the DOM
    a.href = blobURL;
    a.download = file;
    // Click handler that releases the object URL after the element has been clicked
    // This is required for one-off downloads of the blob content
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(blobURL);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    // Add the click event listener on the anchor element
    // Comment out this line if you don't want a one-off download of the blob content
    a.addEventListener("click", clickHandler, false);
    // Programmatically trigger a click on the anchor element
    // Useful if you want the download to happen automatically
    // Without attaching the anchor element to the DOM
    // Comment out this line if you don't want an automatic download of the blob content
    a.click();
    responseBody = "";
  } else if (json) {
    try {
      responseBody = await response.json();
    } catch (err) {
      responseBody = {};
    }
  } else {
    try {
      responseBody = await response.json();
    } catch (err) {
      responseBody = await response.text();
    }
  }

  if (response.status > 299) {
    throw new RequestError(responseBody, response.status);
  }
  debug("end %j", { responseBody, status: response.status });
  return [responseBody, response.status, response];
};

export default sendRequest;
