import * as React from "react";
import PropTypes from "prop-types";
import context from "./context";
import { useAPIAuthorized, usePersistentState } from "../../hooks";
import { useProvidedUser } from "../User";

const propTypes = {
  children: PropTypes.node.isRequired,
};

const cfg = {
  defaultLang: "ga",
};

const LanguageProvider = ({ children }) => {
  const [user, , refreshUser] = useProvidedUser();
  const { lang = cfg.defaultLang } = user || {};
  const [memoryLang = cfg.defaultLang, setMemoryLang] = usePersistentState(
    "cau-lang",
    lang || cfg.defaultLang
  );

  const handleSuccess = React.useCallback(() => {
    refreshUser({});
  }, [refreshUser]);

  const [, doFetch] = useAPIAuthorized(
    {
      endpoint: "user/me/lang",
      method: "PUT",
      wait: true,
    },
    handleSuccess
  );
  const updateLang = React.useCallback(
    (newLang) => {
      doFetch({ body: { lang: newLang } });
      setMemoryLang(newLang);
    },
    [doFetch, setMemoryLang]
  );

  const value = React.useMemo(
    () => [user ? lang : memoryLang, updateLang],
    [lang, memoryLang, updateLang, user]
  );

  return <context.Provider value={value}>{children}</context.Provider>;
};

LanguageProvider.propTypes = propTypes;

export default LanguageProvider;
