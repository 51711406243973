import * as React from "react";
import usePersistentState from "./usePersistentState";
import useAPIAuthorized from "./useAPIAuthorized";

// TODO: remove useAPIAuthorized dependency
// TODO: add expiration
const useAPIPersistent = (key, apiOpts) => {
  const [data, setData, isLoadingState] = usePersistentState(key);
  const [isFetched, setIsFetched] = React.useState(false);
  const handleSuccess = React.useCallback(
    (newData) => {
      setData(newData);
    },
    [setData]
  );
  const [{ isLoading, error }, doFetch] = useAPIAuthorized(
    {
      ...apiOpts,
      wait: true,
    },
    handleSuccess
  );

  React.useEffect(() => {
    if (!data && !isFetched && !isLoadingState) {
      doFetch({});
      setIsFetched(true);
    }
  }, [doFetch, isFetched, data, isLoadingState]);

  return [{ data, isLoading, error }, setData, doFetch];
};

export default useAPIPersistent;
