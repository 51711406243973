import * as React from "react";
import context from "./context";

const useProvidedNotifications = () => {
  const notificationsState = React.useContext(context);

  if (!notificationsState) {
    throw new Error(
      "notifications context must exist to call useProvidedNotifications"
    );
  }
  return notificationsState;
};

export default useProvidedNotifications;
