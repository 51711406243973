import * as React from "react";
import {
  createMuiTheme,
  Box,
  ThemeProvider,
  CssBaseline,
} from "@material-ui/core";
import { esES } from "@material-ui/core/locale";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
  AuthProvider,
  TranslationsProvider,
  LanguageProvider,
  NotificationsProvider,
  ModalProvider,
  useProvidedAuth,
  UserProvider,
  SchoolsProvider,
  useProvidedLanguage,
} from "./contexts";
import TermsAndConditions from "./components/views/TermsAndConditions";
import Header from "./components/layout/Header";
import Loading from "./components/generic/Loading";
import Notifications from "./components/generic/Notifications";
import Footer from "./components/layout/Footer";
import Modal from "./components/layout/Modal";
import { theme as themeConfig, tokenPath } from "./config";
import locale from "./locale";
const LazyAdmin = React.lazy(() => import("./components/views/Admin"));
const LazyGuest = React.lazy(() => import("./components/views/Guest"));
const LazyUser = React.lazy(() => import("./components/views/User"));
const Lazy = ({ children }) => {
  return <React.Suspense fallback={<Loading />}>{children}</React.Suspense>;
};

const theme = createMuiTheme(themeConfig, esES);

function AppWithAuth() {
  const [{ token, data: { scopes = [] } = {} }] = useProvidedAuth();
  if (!token) {
    return (
      <Lazy>
        <LazyGuest />
      </Lazy>
    );
  }

  if (scopes.some((el) => el === "admin")) {
    return (
      <Lazy>
        <LazyAdmin />
      </Lazy>
    );
  }

  return (
    <Lazy>
      <LazyUser />
    </Lazy>
  );
}

const TranslationsProviderWithLang = (props) => {
  const [lang] = useProvidedLanguage();
  return <TranslationsProvider {...props} dictionary={locale[lang]} />;
};

const providers = [
  [ThemeProvider, { theme }],
  [Router],
  [AuthProvider, { tokenPath }],
  [UserProvider],
  [LanguageProvider],
  [TranslationsProviderWithLang],
  [SchoolsProvider],
  [ModalProvider],
  [NotificationsProvider],
];

function App() {
  return providers.reduceRight(
    (app, [Provider, providerProps = {}]) => (
      <Provider {...providerProps}>{app}</Provider>
    ),
    <>
      <Modal />
      <Notifications />
      <Box display="flex" flexDirection="column" minHeight="100vh">
        <CssBaseline />
        <Header />
        <Box flexGrow="1">
          <Switch>
            <Route exact path="/terms-and-conditions">
              <TermsAndConditions />
            </Route>
            <Route path="/">
              <AppWithAuth />
            </Route>
          </Switch>
        </Box>
        <Footer />
      </Box>
    </>
  );
}

export default App;
