import * as React from "react";
import {
  AppBar,
  Button,
  Grid,
  Hidden,
  Toolbar,
  Menu,
  MenuItem,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import {
  useProvidedAuth,
  useProvidedLanguage,
  useProvidedTranslations,
} from "contexts";
import { Link } from "react-router-dom";
import { Menu as MenuButton } from "@material-ui/icons";
import logoTransparenteBlanco from "assets/logoTransparenteBlanco.png";
import { apiBaseURL } from "config";
import DownloadLink from "react-download-link";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  logo: {
    width: 150,
    height: 60,
  },
});

const Header = () => {
  const classes = useStyles();
  const formatMessage = useProvidedTranslations();
  const [{ token }, dispatchToken] = useProvidedAuth();
  const [language, setLanguage] = useProvidedLanguage();
  const [languageMenu, setLanguageMenu] = React.useState(false);
  const [mobileMenu, setMobileMenu] = React.useState(false);
  const handleDownloadManual = async (language) => {
    const formatedLanguage = language.toLowerCase() === "es" ? "ca" : "ga";
    const manualURL = `${apiBaseURL}/manual/download/${formatedLanguage}`;

    const res = await fetch(manualURL);
    return res.arrayBuffer();
  };
  return (
    <nav className={classes.root}>
      <AppBar position="static" color="primary">
        <Toolbar style={{ alignItems: "center", justifyContent: "center" }}>
          <Grid justify="space-between" alignItems="center" container>
            <Grid style={{ justifySelf: "flex-start" }} item>
              <Link to="/user-computer-list">
                <img
                  className={classes.logo}
                  src={logoTransparenteBlanco}
                  alt="Plexus logo"
                />
              </Link>
            </Grid>
            <Hidden xsDown>
              <Grid item>
                <Grid container justify="center">
                  <Button color="inherit">
                    <DownloadLink
                      label="Manual"
                      filename={`ManualCoberturaSIR_Familias_${language.substring(
                        0,
                        2
                      )}_22-23.pdf`}
                      exportFile={async () =>
                        handleDownloadManual(language.substring(0, 2))
                      }
                      style={{
                        color: "white",
                        textDecoration: "none",
                      }}
                    >
                      Manual
                    </DownloadLink>
                  </Button>
                  <Button
                    color="inherit"
                    onClick={(e) =>
                      setLanguageMenu({
                        type: "language",
                        target: e.currentTarget,
                      })
                    }
                  >
                    {formatMessage("app.header.language")}
                  </Button>
                  {token && (
                    <Button
                      color="inherit"
                      onClick={() => dispatchToken({ type: "REMOVE_TOKEN" })}
                    >
                      {formatMessage("app.header.logout")}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Hidden>
            <Hidden smUp>
              {mobileMenu ? (
                <IconButton
                  edge="start"
                  aria-label="menu"
                  color="inherit"
                  onClick={() => setMobileMenu()}
                >
                  <MenuButton />
                </IconButton>
              ) : (
                <IconButton
                  edge="start"
                  aria-label="menu"
                  color="inherit"
                  onClick={(e) => setMobileMenu({ target: e.currentTarget })}
                >
                  <MenuButton />
                </IconButton>
              )}
              <Menu
                keepMounted
                anchorEl={mobileMenu && mobileMenu.target}
                open={mobileMenu}
                onClose={() => setMobileMenu()}
              >
                <MenuItem>
                  <DownloadLink
                    label="Manual"
                    filename={`ManualCoberturaSIR_Familias_${language.substring(
                      0,
                      2
                    )}_21-22.pdf`}
                    exportFile={async () =>
                      handleDownloadManual(language.substring(0, 2))
                    }
                    style={{
                      color: "black",
                      textDecoration: "none",
                    }}
                  >
                    Manual
                  </DownloadLink>
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    setMobileMenu();
                    setLanguageMenu({
                      type: "language",
                      target: e.currentTarget,
                    });
                  }}
                >
                  {formatMessage("app.header.language")}
                </MenuItem>
                {token && (
                  <MenuItem
                    onClick={() => {
                      setMobileMenu();
                      dispatchToken({ type: "REMOVE_TOKEN" });
                    }}
                  >
                    {formatMessage("app.header.logout")}
                  </MenuItem>
                )}
              </Menu>
            </Hidden>
          </Grid>
        </Toolbar>
      </AppBar>

      <Menu
        keepMounted
        anchorEl={languageMenu && languageMenu.target}
        open={languageMenu && languageMenu.type === "language"}
        onClose={() => setLanguageMenu()}
      >
        {["es", "ga"].map((lang) => (
          <MenuItem
            key={lang}
            onClick={() => {
              setLanguage(lang);
              setLanguageMenu();
            }}
          >
            {formatMessage("app.header.language." + lang)}
          </MenuItem>
        ))}
      </Menu>
    </nav>
  );
};

export default Header;
