import * as React from "react";
import context from "./context";

const useProvidedUser = () => {
  const userState = React.useContext(context);

  if (!userState) {
    throw new Error("user context must exist to call useProvidedUser");
  }
  return userState;
};

export default useProvidedUser;
