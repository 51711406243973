import * as React from "react";
import context from "./context";

const useProvidedSchools = () => {
  const schoolsState = React.useContext(context);

  if (!schoolsState) {
    throw new Error("schools context must exist to call useProvidedSchools");
  }
  return schoolsState;
};

export default useProvidedSchools;
