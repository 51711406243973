import * as React from "react";
import Ajv from "ajv";
import AjvFormats from "ajv-formats";
import Debug from "debug";
import { useProvidedTranslations } from "../contexts";

const debug = Debug("app:hooks:useJSONSchema");

const getValue = (key, element) => {
  return key
    .replace(/\[/g, ".")
    .replace(/\]/g, "")
    .split(".")
    .reduce((obj, path) => (obj || {})[path], element);
};

const useJSONSchema = (jsonSchema, extraValidate) => {
  const formatMessage = useProvidedTranslations();
  const validate = React.useMemo(() => {
    const ajv = new Ajv({ allErrors: true });
    AjvFormats(ajv, ["email"]);
    return ajv.compile(jsonSchema);
  }, [jsonSchema]);

  return React.useCallback(
    (payload) => {
      debug("validate", payload);

      const translateError = (error) => {
        if (error.keyword === "minLength") {
          const value = getValue(error.dataPath.replace(/^\//, ""), payload);
          if (!value) {
            return formatMessage("app.validation-errors._required");
          }
        }
        return formatMessage(`app.validation-errors._${error.keyword}`);
      };

      const isValid = validate(payload);
      if (isValid) {
        return {};
      }

      const errorsToParse = [
        ...(validate.errors || []),
        ...(extraValidate ? extraValidate(payload) : []),
      ];
      const validationErrors = errorsToParse.reduce(
        (acc, error) => ({
          ...acc,
          [error.dataPath
            ? error.dataPath.replace(/^\//, "")
            : (error.params && error.params.missingProperty) || "general"]:
            translateError(error),
        }),
        {}
      );

      debug("validation errors are", { validationErrors, errorsToParse });

      return validationErrors;
    },
    [extraValidate, formatMessage, validate]
  );
};

export default useJSONSchema;
