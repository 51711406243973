import * as React from "react";
import PropTypes from "prop-types";
import Debug from "debug";
import context from "./context";

const debug = Debug("App:contexts:Notifications");
const propTypes = {
  children: PropTypes.node.isRequired,
};

const reducer = (state, action) => {
  debug("reducer", action);
  const { type, payload } = action;
  switch (type) {
    case "add":
      return {
        ...state,
        [payload.id]: payload,
      };
    case "remove": {
      const newState = { ...state };
      delete newState[payload.id];
      return newState;
    }
    default:
      return state;
  }
};

const NotificationsProvider = ({ children }) => {
  const [notifications, dispatchNotifications] = React.useReducer(reducer, []);

  const handleDispatch = React.useCallback((action) => {
    const { type, payload } = action;
    switch (type) {
      case "add": {
        const { id = Math.round(Math.random() * 10000), timeout = 3 } = payload;
        if (timeout) {
          setTimeout(() => {
            dispatchNotifications({ type: "remove", payload: { id } });
          }, timeout * 1000);
        }
        dispatchNotifications({ type: "add", payload: { id, ...payload } });
        return id;
      }
      default:
        dispatchNotifications(action);
        return null;
    }
  }, []);

  const contextValue = React.useMemo(
    () => [notifications, handleDispatch],
    [handleDispatch, notifications]
  );
  return <context.Provider value={contextValue}>{children}</context.Provider>;
};

NotificationsProvider.propTypes = propTypes;

export default NotificationsProvider;
