import * as React from "react";
import PropTypes from "prop-types";
import context from "./context";
import { useAPIAuthorized, usePersistentState } from "../../hooks";
import { useProvidedAuth } from "../Auth";

const propTypes = {
  children: PropTypes.node.isRequired,
};

const UserProvider = ({ children }) => {
  const [user, setUser, isLoadingState] = usePersistentState("user-info");
  const [isFetched, setIsFetched] = React.useState(false);
  const [{ data: { _id: userId } = {} }] = useProvidedAuth();
  const handleSuccess = React.useCallback(
    (newUser) => {
      setUser(newUser);
    },
    [setUser]
  );
  const [, doFetch] = useAPIAuthorized(
    {
      endpoint: "user/me",
      method: "GET",
      wait: true,
    },
    handleSuccess
  );

  React.useEffect(() => {
    if (!user && !isFetched && !isLoadingState) {
      doFetch({});
      setIsFetched(userId);
    }
  }, [doFetch, isFetched, isLoadingState, user, userId]);
  React.useEffect(() => {
    if (user && user._id !== userId) {
      setUser();
    }
  }, [setUser, user, userId]);
  React.useEffect(() => {
    if (isFetched && isFetched !== userId) {
      setIsFetched(false);
    }
  }, [isFetched, userId]);
  const value = React.useMemo(
    () => [user, setUser, doFetch],
    [doFetch, setUser, user]
  );
  return <context.Provider value={value}>{children}</context.Provider>;
};

UserProvider.propTypes = propTypes;

export default UserProvider;
