import * as React from "react";
import context from "./context";

const useProvidedAuth = () => {
  const authReducer = React.useContext(context);

  if (!authReducer) {
    throw new Error("auth context must exist to call useProvidedAuth");
  }
  return authReducer;
};

export default useProvidedAuth;
