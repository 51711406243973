import * as React from "react";
import PropTypes from "prop-types";
import context from "./context";
import { useAPIPersistent } from "../../hooks";

const propTypes = {
  children: PropTypes.node.isRequired,
};

const SchoolsProvider = (props) => {
  const { children } = props;
  const apiResp = useAPIPersistent("cau-schools", {
    method: "GET",
    endpoint: "schools",
  });

  return <context.Provider value={apiResp}>{children}</context.Provider>;
};

SchoolsProvider.propTypes = propTypes;

export default SchoolsProvider;
