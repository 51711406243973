import Debug from "debug";

const debug = Debug("app:lib:resizeImage");

function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0) {
    byteString = window.atob(dataURI.split(",")[1]);
  } else {
    byteString = unescape(dataURI.split(",")[1]);
  }
  // separate out the mime component
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new window.Blob([ia], { type: mimeString });
}

function resizeMe(img, maxHeight, maxWidth) {
  debug("resizeMe", { maxHeight, maxWidth });
  const canvas = document.createElement("canvas");

  let width = img.width;
  let height = img.height;

  // calculate the width and height, constraining the proportions
  if (width > height) {
    if (width > maxWidth) {
      // height *= maxWidth / width;
      height = Math.round((height *= maxWidth / width));
      width = maxWidth;
    }
  } else {
    if (height > maxHeight) {
      // width *= maxHeight / height;
      width = Math.round((width *= maxHeight / height));
      height = maxHeight;
    }
  }

  // resize the canvas and draw the image data into it
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0, width, height);

  return dataURItoBlob(canvas.toDataURL("image/jpeg", 0.7)); // get the data from canvas as 70% JPG (can be also PNG, etc.)
}

const resizeImage = async (payload, maxHeight = 1024, maxWidth = 1024) => {
  return new Promise((resolve, reject) => {
    try {
      const blob = new window.Blob([payload]);
      window.URL = window.URL || window.webkitURL;
      const blobURL = window.URL.createObjectURL(blob); // and get it's URL

      // helper Image object
      const image = new window.Image();
      image.src = blobURL;
      // preview.appendChild(image); // preview commented out, I am using the canvas instead
      image.onload = function () {
        // have to wait till it's loaded
        const resized = resizeMe(image, maxHeight, maxWidth);
        debug("resized", resized);
        resolve(resized);
      };
    } catch (err) {
      reject(err);
    }
  });
};

export default resizeImage;
