import * as React from "react";

const useDebounce = (value, cb, interval = 1) => {
  const refCb = React.useRef(cb);
  const refLastCalledValue = React.useRef(value);

  React.useEffect(() => {
    if (refCb.current !== cb) {
      refCb.current = cb;
    }
  }, [cb]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (refLastCalledValue.current !== value) {
        refLastCalledValue.current = value;
        refCb.current(value);
      }
    }, interval * 1000);
    return () => clearTimeout(timeout);
  }, [interval, value]);
};

export default useDebounce;
