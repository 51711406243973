import * as React from "react";
import PropTypes from "prop-types";
import context from "./context";

const propTypes = {
  children: PropTypes.node.isRequired,
  dictionary: PropTypes.shape().isRequired,
};

const TranslationsProvider = ({ children, dictionary }) => (
  <context.Provider value={dictionary}>{children}</context.Provider>
);

TranslationsProvider.propTypes = propTypes;

export default TranslationsProvider;
