import * as React from "react";
import { Dialog, useMediaQuery, useTheme } from "@material-ui/core";
import { useProvidedModal } from "contexts";
const Modal = () => {
  const [modal, dispatchModal] = useProvidedModal();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  if (!modal || !modal.Component) {
    return null;
  }

  const { Component, params, onClose, onSuccess } = modal;
  const handleClose = (...args) => {
    dispatchModal({ type: "hide" });
    if (onClose) {
      onClose(...args);
    }
  };
  const handleSuccess = (...args) => {
    dispatchModal({ type: "hide" });
    if (onSuccess) {
      onSuccess(...args);
    }
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      fullScreen={(params || {}).fullScreen || fullScreen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Component
        {...(params || {})}
        onClose={handleClose}
        onSuccess={handleSuccess}
      />
    </Dialog>
  );
};

export default Modal;
