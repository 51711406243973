import * as React from "react";
import PropTypes from "prop-types";
import Debug from "debug";
import context from "./context";

const debug = Debug("App:contexts:Modal");
const propTypes = {
  children: PropTypes.node.isRequired,
};

const reducer = (state, action) => {
  debug("reducer", action);
  const { type, payload } = action;
  switch (type) {
    case "display":
      return {
        Component: payload.Component,
        onClose: payload.onClose,
        onSuccess: payload.onSuccess,
        params: payload.params,
      };
    case "hide": {
      return {};
    }
    default:
      return state;
  }
};

const ModalProvider = ({ children }) => {
  const modalReducer = React.useReducer(reducer, []);

  return <context.Provider value={modalReducer}>{children}</context.Provider>;
};

ModalProvider.propTypes = propTypes;

export default ModalProvider;
