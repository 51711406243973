import * as React from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { useProvidedTranslations } from "contexts";

const Loading = (props) => {
  const { size = "5rem" } = props;
  const formatMessage = useProvidedTranslations();
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress size={size} />
      {formatMessage("app.loading.text")}
    </Box>
  );
};

export default Loading;
