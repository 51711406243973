import * as React from "react";
import {
  Avatar,
  Box,
  Button,
  Container,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import { useProvidedTranslations } from "contexts";
import logoBlue from "assets/logoBlue.png";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  logo: {
    maxWidth: "100%",
  },
  title: {
    paddingBottom: "10px",
  },
}));

const TermsAndConditions = () => {
  const formatMessage = useProvidedTranslations();
  const classes = useStyles();
  const termsAndConditionsPoints = formatMessage(
    "app.terms-and-conditions.points"
  ).split("|");
  const termsAndConditionsSummary = formatMessage(
    "app.terms-and-conditions.summary.points"
  ).split("|");

  return (
    <Container component="main" maxWidth="sm">
      <div className={classes.paper}>
        <img className={classes.logo} src={logoBlue} alt="Plexus logo" />
        <Box minHeight="20px">
          <Box position="absolute" left="0">
            <Button component={RouterLink} to="/">
              <Avatar className={classes.avatar}>
                <ArrowBackIcon />
              </Avatar>
            </Button>
          </Box>
        </Box>
        <Typography component="h1" variant="h5" className={classes.title}>
          {formatMessage("app.terms-and-conditions.title")}
        </Typography>
        <Typography component="p" variant="body2">
          {formatMessage("app.terms-and-conditions.body")}
        </Typography>
        <ul>
          {termsAndConditionsPoints.map((term) => (
            <Typography component="li" variant="body2" key={term}>
              {term}
            </Typography>
          ))}
        </ul>
        <Typography component="h2" variant="h5">
          {formatMessage("app.terms-and-conditions.summary.title")}
        </Typography>
        <ul>
          {termsAndConditionsSummary.map((term) => (
            <Typography component="li" variant="body2" key={term}>
              {term}
            </Typography>
          ))}
        </ul>
      </div>
    </Container>
  );
};

export default TermsAndConditions;
