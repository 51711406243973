import * as React from "react";
import PropTypes from "prop-types";
import context from "./context";

const propTypes = {
  children: PropTypes.node.isRequired,
  tokenPath: PropTypes.string.isRequired,
};

const getStorageHandler = (tokenPath) => ({
  read: () => window.localStorage.getItem(tokenPath),
  write: (value) => window.localStorage.setItem(tokenPath, value),
  delete: () => window.localStorage.removeItem(tokenPath),
});

function parseJwt(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case "NEW_TOKEN": {
      let data;
      try {
        data = parseJwt(payload);
      } catch (err) {
        data = {};
      }
      return { token: payload, isLoading: false, data };
    }
    case "REMOVE_TOKEN":
      return {};
    case "EMPTY_TOKEN":
      return {};
    case "LOAD_TOKEN":
      return { isLoading: true };
    default:
      return { ...state };
  }
};

const useAuth = (tokenPath) => {
  const [auth, dispatchAuth] = React.useReducer(reducer, { isLoading: true });
  const storageHandler = React.useMemo(
    () => getStorageHandler(tokenPath),
    [tokenPath]
  );

  React.useLayoutEffect(() => {
    const token = storageHandler.read(tokenPath);
    if (token) {
      dispatchAuth({ type: "NEW_TOKEN", payload: token });
    } else {
      dispatchAuth({ type: "EMPTY_TOKEN" });
    }
    return () => dispatchAuth({ type: "LOAD_TOKEN" });
  }, [dispatchAuth, storageHandler, tokenPath]);

  const handleDispatchAuth = React.useCallback(
    (action) => {
      const { type, payload } = action;
      switch (type) {
        case "NEW_TOKEN":
          storageHandler.write(payload);
          break;
        case "REMOVE_TOKEN":
        case "EMPTY_TOKEN":
          storageHandler.delete();
          break;
        default:
      }
      dispatchAuth(action);
    },
    [storageHandler]
  );

  return React.useMemo(
    () => [auth, handleDispatchAuth],
    [auth, handleDispatchAuth]
  );
};

const AuthProvider = ({ tokenPath, children }) => {
  const authReducer = useAuth(tokenPath);
  return <context.Provider value={authReducer}>{children}</context.Provider>;
};

AuthProvider.propTypes = propTypes;

export default AuthProvider;
