export const tokenPath =
  process.env.REACT_APP_TOKEN_PATH || "seguros-cau-token";
export const apiBaseURL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:8000";
export const validMimeTypes = (
  process.env.REACT_APP_VALID_MIME_TYPES ||
  "image/tiff,image/gif,image/png,image/jpeg,image/bmp,image/webp,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.oasis.opendocument.text"
).split(",");
export const validPCModels = [
  "HP ProBook x360 11 G1 EE (Rojo)",
  "Lenovo Yoga 11e",
  "HP Probook x360 11 G5/G9 EE (Gris)",
];
export const theme = {
  palette: {
    primary: {
      main: "#0A3D58",
    },
    secondary: {
      main: "#DA6D72",
    },
  },
  typography: {
    fontFamily: ["Open Sans", "sans-serif"].join(","),
  },
};
export const payRedsysURL = process.env.REACT_APP_PAY_REDSYS_URL || 'https://sis.redsys.es/sis/realizarPago';
export const accountNumber = process.env.REACT_APP_ACCOUNT_NUMBER || 'ES7101287671170100003364';
export const insuranceAmount = process.env.REACT_APP_INSURANCE_AMOUNT || '49€';
export const conceptTransfer = process.env.REACT_APP_CONCEPT || 'Cobertura SIR';

