import * as React from "react";
import Debug from "debug";
import { sendRequest } from "../lib";
import { useProvidedAuth } from "../contexts";

const debug = Debug("app:hooks:useAPI");
const apiOptsReducer = (state, action) => ({
  ...state,
  ...(typeof action === "function" ? action(state) : action),
  wait: false,
  requestsCount: (state.requestsCount || 0) + 1,
});

const apiStatusReducer = (state, action) => {
  debug("apiStatusReducer", action);
  const { type, payload } = action;
  switch (type) {
    case "fetch-init":
      return {
        isLoading: true,
      };
    case "fetch-end":
      return {
        isLoading: false,
        data: payload.data,
        status: payload.status,
      };
    case "fetch-error":
      return {
        isLoading: false,
        error: payload.error,
        status: payload.status,
      };
    default:
      return state;
  }
};

const loadingTimeout = 0.5; // Secs

const useAPI = (initialOpts, onSuccess, onError) => {
  const [apiOpts, dispatchAPIOpts] = React.useReducer(
    apiOptsReducer,
    initialOpts
  );
  const [apiStatus, dispatchAPIStatus] = React.useReducer(apiStatusReducer, {
    isLoading: !apiOpts.wait,
  });
  const [, dispatchToken] = useProvidedAuth();

  // We want to avoid re-enter into useEffect if onSuccess/onError changes
  // so we are going to save this functions in refs
  const refHandleSuccess = React.useRef();
  React.useEffect(() => {
    if (refHandleSuccess.current !== onSuccess) {
      refHandleSuccess.current = onSuccess;
    }
  }, [onSuccess]);
  const refHandleError = React.useRef();
  React.useEffect(() => {
    if (refHandleError.current !== onError) {
      refHandleError.current = onError;
    }
  }, [onError]);

  React.useEffect(() => {
    debug("effect apiOpts", apiOpts);
    if (apiOpts.wait) {
      return;
    }

    let isCanceled = false;
    const setLoadingTimeout = setTimeout(() => {
      if (isCanceled) {
        return;
      }
      dispatchAPIStatus({ type: "fetch-init" });
    }, loadingTimeout * 1000);

    const handleRequest = async () => {
      try {
        debug("go to sendRequest");
        const [data, status] = await sendRequest(apiOpts);
        debug("exit sendRequest");
        clearTimeout(setLoadingTimeout);
        debug("data is", data);
        if (isCanceled) {
          return;
        }
        isCanceled = true;
        dispatchAPIStatus({ type: "fetch-end", payload: { data, status } });
        if (refHandleSuccess.current) {
          refHandleSuccess.current(data, status, apiOpts);
        }
      } catch (error) {
        debug("catch sendRequest error", error);
        clearTimeout(setLoadingTimeout);
        if (isCanceled) {
          return;
        }
        if (error.status === 401 && apiOpts.token) {
          dispatchToken({ type: "REMOVE_TOKEN" });
          return;
        }
        isCanceled = true;
        dispatchAPIStatus({
          type: "fetch-error",
          payload: { error, status: error.status },
        });
        if (refHandleError.current) {
          refHandleError.current(error, error.status, apiOpts);
        }
      }
    };
    handleRequest();
    // eslint-disable-next-line consistent-return
    return () => {
      isCanceled = true;
    };
  }, [apiOpts, dispatchToken]);

  return [apiStatus, dispatchAPIOpts, apiOpts];
};

export default useAPI;
