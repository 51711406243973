import * as React from "react";
import useAPI from "./useAPI";
import { useProvidedAuth } from "../contexts";

const useAPIAuthorized = (opts, onSuccess, onError) => {
  const [{ token: bearer }] = useProvidedAuth();
  const [waitingToken, setWaitingToken] = React.useState(
    !bearer && !opts.wait && {}
  );

  const [apiStatus, dispatchAPI, apiOpts] = useAPI(
    { ...opts, bearer, wait: opts.wait || !bearer },
    onSuccess,
    onError
  );

  React.useEffect(() => {
    if (waitingToken && bearer) {
      dispatchAPI({ ...waitingToken, bearer });
      setWaitingToken(false);
    }
  }, [bearer, dispatchAPI, waitingToken, setWaitingToken]);

  const dispatchAPIOpts = React.useCallback(
    (newOpts) => {
      if (bearer) {
        if (typeof newOpts === "function") {
          dispatchAPI((prev) => newOpts({ ...prev, bearer }));
          return;
        }
        dispatchAPI({ bearer, ...newOpts });
        return;
      }

      if (typeof newOpts === "function") {
        setWaitingToken((prev) => newOpts({ ...apiOpts, ...prev }));
        return;
      }
      setWaitingToken((prev) => ({ ...prev, ...newOpts }));
    },
    [apiOpts, bearer, dispatchAPI, setWaitingToken]
  );

  return [apiStatus, dispatchAPIOpts, apiOpts];
};

export default useAPIAuthorized;
