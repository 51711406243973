import * as React from "react";
import Debug from "debug";

const debug = Debug("app:hooks:usePersistentState");

const getCache = (key, expirationTime) => ({
  read: () => {
    debug("read", key);
    const readValue = window.localStorage.getItem(key);
    if (!readValue) {
      return null;
    }
    const parsedValue = JSON.parse(readValue);
    if (parsedValue.createdAt + expirationTime <= Date.now() / 1000) {
      debug("isExpired", {
        createdAt: parsedValue.createdAt,
        expirationTime,
        now: Date.now() / 1000,
      });
      debug("expired", key);
      return null;
    }
    return parsedValue.value;
  },
  write: (value) => {
    debug("write", key, value);
    if (!value) {
      window.localStorage.removeItem(key);
      return;
    }

    window.localStorage.setItem(
      key,
      JSON.stringify({
        value,
        createdAt: Math.floor(Date.now() / 1000),
      })
    );
  },
  delete: () => {
    window.localStorage.removeItem(key);
  },
});

const usePersistentState = (key, initialValue, expirationTime = 3600) => {
  const [value, setValue] = React.useState();
  const [isLoading, setIsLoading] = React.useState(true);
  const cache = React.useMemo(
    () => getCache(key, expirationTime),
    [expirationTime, key]
  );
  React.useEffect(() => {
    debug("read from cache");
    const item = cache.read();
    if (item) {
      setValue(item);
    } else {
      cache.write(initialValue);
      setValue(initialValue);
    }
    setIsLoading(false);
  }, [cache, initialValue]);

  const handleSetValue = React.useCallback(
    (newValue) => {
      debug("handleSetValue");
      if (typeof newValue === "function") {
        setValue((prev) => {
          const parsedNewValue = newValue(prev);
          debug("parsedNewValue", parsedNewValue, prev);
          cache.write(parsedNewValue);
          return parsedNewValue;
        });
        return;
      }
      cache.write(newValue);
      setValue(newValue);
    },
    [cache]
  );

  return [value, handleSetValue, isLoading];
};

export default usePersistentState;
