import * as React from "react";
import context from "./context";

const useProvidedModal = () => {
  const modalState = React.useContext(context);

  if (!modalState) {
    throw new Error("modal context must exist to call useProvidedModal");
  }
  return modalState;
};

export default useProvidedModal;
