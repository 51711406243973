import * as React from "react";
import context from "./context";

const useProvidedTranslations = () => {
  const dictionary = React.useContext(context);

  return React.useCallback(
    (key, params) => {
      const messageToParse = (dictionary && dictionary[key]) || key;
      if (!params) {
        return messageToParse;
      }
      return Object.entries(params).reduce(
        (message, [paramName, paramValue]) =>
          message.replace(new RegExp(`{${paramName}}`, "g"), paramValue),
        messageToParse
      );
    },
    [dictionary]
  );
};

export default useProvidedTranslations;
