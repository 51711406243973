import * as React from "react";
import { useProvidedTranslations } from "contexts";
import { Box, makeStyles } from "@material-ui/core";
import logoTransparente from "assets/logoTransparente.png";

const useStyles = makeStyles((theme) => ({
  logo: {
    width: "180px",
    height: "70px",
  },
  footer: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.grey[100],
  },
}));

const Footer = () => {
  const formatMessage = useProvidedTranslations();
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box
          flexGrow="1"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <p>
            {formatMessage("app.footer.copyright", {
              year: new Date().getFullYear(),
            })}
          </p>
        </Box>
        <Box
          flexGrow="1"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <a
            href="https://www.plexus.es/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={logoTransparente} alt="plexus" className={classes.logo} />
          </a>
        </Box>
      </Box>
    </footer>
  );
};

export default Footer;
