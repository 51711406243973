import * as React from "react";
import context from "./context";

const useProvidedLanguage = () => {
  const languageState = React.useContext(context);

  if (!languageState) {
    throw new Error("language context must exist to call useProvidedLanguage");
  }
  return languageState;
};

export default useProvidedLanguage;
